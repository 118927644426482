'use client';

import ContentWrapper from '@/components/ContentWrapper';
import RichText from '@/components/RichText';
import Rings from '@/components/Rings';
import Button from '@/components/atoms/Button';
import SanityImage from '@/components/atoms/SanityImage';
import { useLinkUrl } from '@/hooks/useLinkUrl';
import useWindowSize from '@/hooks/useWindowSize';
import { BaseBlockComponentProps } from '@/types/block';
import { LinkType, SanityImageType } from '@/types/generic';
import { NextArray } from '@grace-studio/graceful-next/types';
import type { PortableTextBlock } from '@portabletext/types/dist';
import classNames from 'classnames';
import { MotionValue, motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

type BackgroundColor = 'brown-1' | 'brown-2' | 'brown-3' | 'brown-4';

type InspirationBlockProps = BaseBlockComponentProps<{
  backgroundColor: BackgroundColor;
  tag: string;
  heading1: string;
  heading2: string;
  image: SanityImageType;
  rings?: boolean;
  button?: LinkType;
  text?: NextArray<PortableTextBlock>;
}>;

const InspirationBlock: InspirationBlockProps = ({ block }) => {
  const { mobileView } = useWindowSize();
  const { linkUrl } = useLinkUrl(block.button);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ['start end', 'end start'],
  });

  const background: Record<BackgroundColor, string> = {
    'brown-1': 'bg-brown-1',
    'brown-2': 'bg-brown-2',
    'brown-3': 'bg-brown-3',
    'brown-4': 'bg-brown-4',
  };

  const ringsProps = mobileView
    ? {
        leftRingDistanceY: 300,
        rightRingDistanceY: 200,
        leftRingDistanceX: 200,
        rightRingDistanceX: 20,
        leftRingWidth: 184,
        rightRingWidth: 184,
      }
    : {
        leftRingDistanceY: 150,
        rightRingDistanceY: 400,
        leftRingDistanceX: 10,
        rightRingDistanceX: 90,
        leftRingWidth: 394,
        rightRingWidth: 255,
      };

  const useParallax = (value: MotionValue<number>, distance: number) =>
    useTransform(value, [0, 1], [`${distance}%`, '0%']);

  return (
    <ContentWrapper className="grid-base relative overflow-hidden text-BW-white">
      <motion.div
        ref={scrollRef}
        initial="offScreen"
        viewport={{ amount: 0.2, once: true }}
        whileInView="onScreen"
        className={classNames(
          'grid-base overflow-hidden pb-[120px] pt-20 grid-cols-[auto_auto] col-span-full rounded-3xl relative',
          background[block.backgroundColor]
        )}
      >
        {block.rings && (
          <Rings
            scrollYProgress={scrollYProgress}
            leftRingClass="absolute lg:justify-self-end -left-[15%] md:-left-[5%] lg:left-40 top-[40%] lg:top-[10%]"
            rightRingClass="absolute top-[5%] right-6 lg:right-72 lg:top-1/3"
            {...ringsProps}
          />
        )}

        <motion.span
          style={{
            y: useParallax(scrollYProgress, 650),
          }}
          className="relative text-center mb-6 lg:mb-20 text-BW-white col-span-full"
        >
          {block.tag}
        </motion.span>

        <motion.div
          style={{
            y: useParallax(scrollYProgress, 20),
          }}
          className="grid grid-cols-4 aspect-[4/5] relative gap-y-4 gap-x-[inherit] col-span-4 col-start-2 lg:col-start-5"
        >
          <SanityImage
            image={block.image}
            height={600}
            width={450}
            className="col-span-full relative z-10 rounded-3xl w-full object-cover"
          />
          {block.text && (
            <RichText
              className="col-span-full lg:col-span-3 lg:col-start-2 pb-6"
              text={block.text}
            />
          )}
          {block.button && (
            <Button
              className="col-span-full w-full"
              type="secondary"
              style="light"
              size="big"
              href={linkUrl}
            >
              {block.button.text}
            </Button>
          )}
        </motion.div>

        <motion.div
          className="text-xl uppercase w-full top-[26%] lg:top-1/3  whitespace-nowrap absolute col-span-full flex flex-col gap-y-14 lg:gap-y-28 z-20"
          aria-hidden
        >
          <motion.span
            className="relative self-end lg:right-10"
            style={{
              x: useParallax(scrollYProgress, mobileView ? 80 : 60),
            }}
          >
            {block.heading1}
          </motion.span>

          <motion.span
            className="ml-20 lg:ml-40 text-right"
            style={{
              x: useParallax(scrollYProgress, mobileView ? -240 : -100),
            }}
          >
            {block.heading2}
          </motion.span>
        </motion.div>
      </motion.div>
    </ContentWrapper>
  );
};

export default InspirationBlock;
