import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types/dist';
import classNames from 'classnames';
import { FC } from 'react';

type RichTextProps = {
  text: PortableTextBlock[];
  className?: string;
};

const RichText: FC<RichTextProps> = ({ text, className }) => {
  const innerText = text?.map((_text) => ({
    ..._text,
    children: _text.children.map((child) => ({
      ...child,
      text: child.text.replace(/\s+/g, ' '),
    })),
  }));

  return (
    <div className={classNames('gap-y-6', className ?? 'flex flex-col')}>
      <PortableText value={innerText} />
    </div>
  );
};

export default RichText;
